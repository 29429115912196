export const primaryColors = [
    /* design v1 */
    {className: 'color-1'},
    {className: 'color-2'},
    {className: 'color-3'},
    /* design v2  */
    {className: 'color-4'},
    {className: 'color-5'},
    {className: 'color-6'},
    
]

export const backgroundColors = [
    {className: 'bg-1'}, 
    {className: 'bg-2'}
]

const titles = {
    h3: 'Customize Your Theme',
    small: 'Change the primary color to your preference.',
    h5: 'Primary Color'
};

export { titles };