import KNTEU from '../../assets/knteu3.png'
import KPI from '../../assets/kpi.png'
import KNT from '../../assets/knteu.png'
import KP from '../../assets/kp.jpeg'

const data = [
    {id: 1, link: 'https://knute.edu.ua/', animation: 'flip-right', nm: 'kn', img:KNT, icon: KNTEU, title: 'BE  in Software Engineering', name: 'KYIV NATIONAL UNIVERSITY OF TRADE AND ECONOMICS', desc: "Algorithms & Data Structures,  Object-Oriented Programming,  Web-Applications Development,  Databases & Information Systems,  Cloud Technologies,  Functional Programming,  Calculus, Linear Algebra, Probability Theory  and  Statistics."},
    {id: 2, link: 'https://www.kpi.ua/en/', animation: 'flip-up',nm: 'kp', img:KP, icon: KPI, title: 'MS  in Computer Sciences', name: 'IGOR SIKORSKY KYIV POLYTECHNIC INSTITUTE', desc: "Cybernetics,  Machine learning,  Research methods of complex systems and processes,  High-performance distributing systems,  Ultra-large data sets processing: Methods and technologies of computational intelligence."},
]

export default data

const titles = {
    h2: 'Education',
    p: 'In this part find brief information about my Academic Degree, during it, I explored numerous topics of Computer Sience with emphasis on applying complex theoretical material to creating and optimization software.'
};

export { titles };