import { FcBullish } from "react-icons/fc";
import { FcGenealogy } from "react-icons/fc";
import { FcServices } from "react-icons/fc";

const data = [
    {id: 1, icon: <FcBullish/>, title: 'Methodologies',  p1: "Agile", p2: "Scrum", p3: "Kanban", p4:"ScrumBan", p5:"Waterfall", p6: "Lean", p7: "SAFe"}, 
    {id: 2, icon: <FcGenealogy/>, title: 'Practices', p1: "OOP", p2: "Algorithms", p3: "Data Structures", p4: "Service-Oriented Architecture", p5: "Event-Driven Architecture", p6:"Design Patterns", p7:"Web Scraping", p8:"Machine Learning"},
    {id: 5, icon: <FcServices/>, title: 'Technologies', p1: "React, Redux", p2: "JavaScript, TypeScript", p3: "PostrgreSQL, SQLite", p4: "Integration, Unit testing", p5: "Python", p6: "Java", p7: "Git"},
]

export default data

const titles = {
    h2: 'My Skills',
    title: "In this part, I listed my main skills and knowledge connected with all SDLC. I'm working to give you the best in all below.",
};

export { titles };


