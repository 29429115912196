import {HiOutlineMail} from 'react-icons/hi'
import {BsLinkedin} from "react-icons/bs";
import {BsTelegram} from 'react-icons/bs'

const data = [
    {id: 1, animation: "zoom-in-right", icon: <BsLinkedin/>, link: 'https://www.linkedin.com/in/dmytro-t-148378252'},
    {id: 2, animation: "zoom-in-up", icon: <HiOutlineMail/>, link: 'mailto:dimitry7713@gmail.com'},
    {id: 3, animation: "zoom-in-left", icon: <BsTelegram/>, link: 'https://t.me/somenmsrnm'},
]

export default data

const titles = {
    h2: 'Get In Touch',
    p: 'Shoot me a message via any of the links below !',
};

export { titles };