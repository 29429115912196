import data from './data'
import './skills.css'
import Card from '../../components/Card'
import AOS from 'aos'
import { titles } from './data';
import { useEffect } from 'react'
import 'aos/dist/aos.css'

const Skills = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <section id="skills">
      <h2>{titles.h2}</h2>
      <p>{titles.title}</p>
      <div className='container skills__container' data-aos="flip-down">
        {data.map(item => (
          <Card key={item.id} className="skill light">
            <div className='skill__header'>
              <div className='skill__icon'>{item.icon}</div>
            </div>
            <div className='skill__details'>
              {Object.keys(item).map((key, index) => (
                key.startsWith('p') && <h4 key={index}>{item[key]}</h4>
              ))}
            </div>
          </Card>
        ))}
      </div>
    </section>
  );
}

export default Skills;
