import {FiMail} from 'react-icons/fi'
import {BsTelegram} from 'react-icons/bs'
import { BsLinkedin } from "react-icons/bs";

const data = [
    {id:1, link: 'https://www.linkedin.com/in/dmytro-t-148378252', ref: 'inst', icon: <BsLinkedin/>},
    {id:2, link: 'mailto:dimitry7713@gmail.com', ref: 'mail', icon: <FiMail/>},
    {id:3, link: 'https://t.me/somenmsrnm', ref: 'telegram', icon: <BsTelegram/>}
]

export default data

const titles = {
    name: 'Dmytro Tarasov',
    description: 'Experienced software developer and technical project manager with a unique combination of technology expertise and exceptional communication skills.',
    talk: "Let's Talk",
    experience : "My Experience"
};

export { titles };