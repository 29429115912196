import './experience.css'
import data from './data'
import { titles } from './data';
import Card from '../../components/Card'

const Experience = () => {
  return (
    <section id ='experience'>
        <h2>{titles.title}</h2>
        <p id='title__p'>{titles.subtitle}</p>
        <div className='container experience__container'>
        {
            data.map(item => (
                <Card key={item.id} className="experience__item" >
                    <div className="experience__item-image">
                        <a href={item.link} >
                           <img src={item.img} alt="" />
                        </a>
                    </div>
                    <h4>{item.title}</h4>
                    <p><strong>{titles.focus}</strong>{item.focus}</p>
                    <p><strong>{titles.tech}</strong>{item.tech}</p>
                    <div className='pitch__part'>
                        <img className='pitch__img' alt="" src={item.pitchimg}/>
                        <p>{item.pitch}</p>
                    </div>
                    <>{item.projects ?<><div></div><div className="experienceProjects"><h4>{titles.projects}</h4></div></>: null}</>
                    <ul className='experience__list'>
                        {Object.entries(item).map(([key, value]) => (key.startsWith('d') && value ? (
                            <li key={key}>
                                <div className='experience__list-icon'>{item.icon}</div>
                                <p>{value}</p>
                            </li>
                        ) : null))}
                    </ul>
                </Card>
            ))
        }
        </div>
    </section>
  )
}

export default Experience