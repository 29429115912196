import Model from "../components/Model"
import { primaryColors } from "./data"
import { titles } from './data';
import PrimaryColor from "./PrimaryColor"
import './theme.css'

const Theme = () => {
  return (
    <Model className="theme__model">
        <h3>{titles.h3}</h3>
        <small>{titles.small}</small>
        <div className="theme__primary-wrapper" id="">
            <h5>{titles.h5}</h5>
            <div className="theme__primary-colors">
            {
                primaryColors.map(pColor => <PrimaryColor key={pColor.className} className={pColor.className}/>)
            }
        </div>
        </div>
    </Model>
  )
}

export default Theme