import IMG5 from '../../assets/flx.png'
import IMG6 from '../../assets/str.png'
import IMG7 from '../../assets/fire-dynamic-color.png'
import IMG8 from '../../assets/target-dynamic-color.png'
import IMG9 from '../../assets/web.jpg'
import IMG10 from '../../assets/at.png'
import {FaTools} from 'react-icons/fa'
import {BsBriefcaseFill} from 'react-icons/bs'

const data = [
    {
        id: 1, 
        anim: "fade-up",
        img: IMG9, 
        link: 'https://www.orisha.com/',
        title: 'Full-Stack Engineer at Orisha · 1 yr', 
        focus: 'POS, ERP, WMS', 
        tech:' react.js · javascript · redux · css prepocessors · typescript · css · jest · unit testing · cypress · html · java · postgreSQL · webSQL · jenkins', 
        pitchimg: IMG7,
        pitch: "Collaborated within a team to develop React-based Point of Sale/Service system. The system has gained widespread adoption, being utilized by renowned brands such as Nike and Decathlon France.",
        projects: true,
        icon: <FaTools/>,
        d1: 'Cash Management: engineered and implemented cash management module, streamlining the handling of cash transactions within the POS system.', 
        d2:'Multicurrency Payments: designed and integrated a multicurrency payment system to facilitate transactions for international clients.', 
    }, 

    {
        id: 2, 
        anim: "fade-left",
        img: IMG6,
        title: 'Technical Project Manager at Blackstone · 1.5 yrs', 
        focus: 'CRM , CMS , AI', 
        tech: 'jira · python · tableau · grafana · postgreSQL · react.js · javascript · prometheus · new relic · jenkins · cloudflare · elasticsearch', 
        pitchimg: IMG8,
        pitch: 'Managed a team of 15+ direct subordinates, overseeing diverse departments including backend, machine learning, frontend, DevOps and QA, guiding end-to-end product development.',
        projects: true,
        icon: <BsBriefcaseFill/>,
        d1:'Led the development of Business Process Management platform, automating diverse business processes, eliminating the necessity for extensive coding. Business impact : resulted in a 28% increase in management operational efficiency and a 11% reduction in costs.', 
        d2:'Led the implementation of machine learning for decision making based on customer satisfaction metrics. Business impact : contributed to 16% improvement in customer satisfaction.',
    }, 

    {
        id: 3, 
        anim: "fade-right",
        img: IMG5, 
        link: 'https://flex.com/',
        title: 'Software Developer at Flex · 2 yrs', 
        focus: 'ERP , WMS , SRM , EDI', 
        tech:' react.js · javascript · html · css · python · urwid · postgreSQL · java · aioHTTP · rocketU2 · kubernetes · jenkins', 
        pitchimg: IMG10,
        pitch: "Collaborated within a team to develop business management software for internal use in the company's factories and warehouses.",
        icon: <FaTools/>,
        d1: "Created an ETL and report generation application for factory-related processes to replace manual work with excel macro.",
        d2: "Created a CLI library using urwid to spin up CLI apps on different factory's embedded devices.",
        d3: "Created a modern and user-friendly web interface for displaying report data and business-related information.",
        d4: "Created a web application for warehouse inventory management to enhance overall user experience and add a dynamic touch, replacing the existing old-fashioned terminal interface.",
        d5: "Enhanced various existing coders and decoders for EDI signals used in communication between factory's customers.",
        d6: "Improved existing applications for data interchange with customer APIs.",
    }, 

]

export default data;

const titles = {
    title: 'Experience',
    subtitle: "Learn more about my Commercial Practice in this division.",
    tech: "Technologies  :  ",
    focus: "Focus on  :  ",
    projects: "Projects  :  "
};

export { titles };