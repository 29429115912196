const data = [
    {
        id: 1,
        question: "What are your salary expectations?",
        answer: "At this point in my search, I'm flexible in certain numbers and mainly focused on finding a role related to technologies and methodologies I  would like to work with. So, once I've done that, I'm happy to consider an offer that you feel is fair for the job duties."
    }, 
    {
        id: 2,
        question: "Are You a Team Player?",
        answer: "I have served as both a team leader and a team contributor, so I really enjoy working in a team environment, and I get along well with people.  Regardless of the role I'm put in, I utilize my communication skills, positive attitude, and outgoing personality so that I can become a key contributor."
    }, 
    {
        id: 3,
        question: "What's your work style?",
        answer: "I tend to maintain a friendship-friendly workplace. Studies confirm that whether a workplace is fully in-person or remote, a culture that prioritizes and encourages work friendships is beneficial for employees and has a significant positive impact on business productivity and profitability. In my last position, I actually implemented practices that streamlined communication among team members. As a result, the entire delivery became more efficient in real terms."
    }, 
    {
        id: 4,
        question: "How do you deal with pressure or stressful situations?",
        answer: "I stay motivated by thinking about the end result. I've found that even in the midst of a challenging situation, reminding myself of my goals helps me take a step back and stay positive."
    },
    {
        id: 5,
        question: "How do you approach problem-solving in web development?",
        answer: "My approach involves breaking down complex problems into smaller, manageable tasks. I prioritize understanding the user's perspective, and I'm committed to writing clean, efficient code to create effective and user-friendly solutions."
    }, 
    {
        id: 6,
        question: "Are you comfortable working with version control systems?",
        answer: "Yes, I am proficient in using Git for version control. I have experience with branching, merging, and resolving conflicts, ensuring a smooth collaborative development process."
    }, 
    {
        id: 7,
        question: "How long does it take you to start onboarding if you receive a job offer?",
        answer: "Due to my work contract, I need two weeks to complete all my tasks or hand them over. "
    }, 
    {
        id: 8,
        question: "How do you stay updated on the latest trends and technologies in web development?",
        answer: "I am dedicated to continuous learning and stay updated through online communities, industry blogs, and attending relevant conferences. I regularly participate in workshops and explore new technologies to enhance my skill set."
    },
]

export default data

const titles = {
    h2: 'Frequently Asked Questions',
    p: 'Here are some questions I usually get. Click to toggle the answer, and if you still have some more questions, shoot me a message from the contact section !',
};

export { titles };