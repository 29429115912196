import './questions.css'
import faqs from './data'
import { titles } from './data';
import FAQ from './FAQ'
import AOS from 'aos'
import { useEffect } from 'react'
import 'aos/dist/aos.css'

const Questions = () => {
  useEffect(() => {
    AOS.init({duration: 2000})
  }, [])
  return (
    <section id="questions">
      <h2>{titles.h2}</h2>
      <p>{titles.p}</p>
      <div className='container faqs__container' data-aos="zoom-in">
        {
          faqs.map(faq => (
            <FAQ key={faq.id} faq={faq}/>
          ))
        }
      </div>
    </section>
  )
}

export default Questions