import './about.css'
import AboutImage from '../../assets/me-about.jpg'
import data from './data'
import { titles } from './data';
import Card from '../../components/Card'
import {HiDownload} from 'react-icons/hi'
import CV from '../../assets/cv.pdf'

const About = () => {
  return (
    <section id="about" >
      <div className='container about__container'>

        <div className='about__left' data-aos="fade-right">

          <div className='about__portrait'>
            <div className='about__portrait-image' >
              <img src={AboutImage} alt=""/>
            </div>
          </div>

        </div>
        <div className='about__right' data-aos="fade-left">
          <h5>{titles.h5}</h5>
          <h2>{titles.h2}</h2>
          <div className='about__cards'>
            {
              data.map(item => (
                <Card key={item.id} className='about__card'>
                  <img src={item.icon} alt="" className='about__card-icon' />
                  <h5>{item.title}</h5>
                  <small>{item.desc}</small>
                </Card>
              ))
            }

          </div>
          <p>
          {titles.title}
          </p>
          <p>
          {titles.subtitle}
          </p>
          <a href={CV} download className='btn primary'>{titles.button}<HiDownload/></a> 
        </div>
      </div>
    </section>
  )
}

export default About